import { useRef, useCallback, useEffect, RefObject } from "react";

function useInfiniteScroll(callbackParam: () => void, containerRef: RefObject<HTMLDivElement>, isActive: boolean = false) {
  const observer = useRef<IntersectionObserver | null>(null)

  const callback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (isActive || (entries.length === 0 && isActive)) return

      if (entries[0].isIntersecting) callbackParam()
    },
    [callbackParam, isActive],
  );

  const infiniteScrollRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) {
      observer.current.disconnect()
    }

    if (node) {
      observer.current = new IntersectionObserver(callback, {
        root: containerRef.current,
        rootMargin: "-30px 0px 0px 0px",
        threshold: 1,
      })
      observer.current.observe(node)
    }
  }, [callback, containerRef])

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  return infiniteScrollRef
}

export default useInfiniteScroll