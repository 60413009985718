export const createId = () => Math.random().toString(16).slice(2)

export const currency = (amount: number) => new Intl.NumberFormat('en-MY', {
  style: "decimal",
  currency: 'MYR',
}).format(amount)

export const copy = (string: string) => {
  return new Promise(() => {
    if (navigator.clipboard) navigator.clipboard.writeText(string).then(() => Promise.resolve(`copied "${string}"`))
  })
}