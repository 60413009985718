import { onAuthStateChanged } from 'firebase/auth';
import { isSupported } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { auth, handleNotificationPermission, handleToken } from './firebase/firebase';
import NotFound from './pages/NotFound';
import routes, { routesElements } from './routes';
import "./styles/main.scss";

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  const [messagingAPISupported, setMessagingAPISupported] = useState(false)
  isSupported().then((e) => setMessagingAPISupported(e))

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      console.log(u?.uid, u?.displayName)
      if (!u && location.pathname !== routes.signin && location.pathname !== routes.signup) return navigate(routes.signin)
      handleToken()
        .catch(e => console.error(e))
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (messagingAPISupported) handleNotificationPermission()
  }, [messagingAPISupported])

  return (
    <>
      <Routes>
        {routesElements.map(route => <Route key={route.path} path={route.path} element={route.element} />)}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App;
