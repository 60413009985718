import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { init, SearchIndex } from "emoji-mart";
import { addDoc, collection, doc, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import * as linkify from "linkifyjs";
import { memo, useEffect, useRef } from "react";
import { Edit2, Send, Smile, X } from "react-feather";
import placeholder from "../../assets/placeholder.svg";

import { ChatContextType, MessageData } from '../../data/types';
import { auth, db, functions } from '../../firebase/firebase';
import { useChatContext } from "../../pages/Chat";

const ChatInput = () => {
  const {
    message,
    emojiSearch,
    editMessageData,
    setMessage,
    emojiIsOpen,
    setEmojisOpen,
    setEmojiSearch,
    setEmojiSearchSelected,
    emojiSearchSelected,
    setEditMessageData,
    chat,
    messagesPath,
  } = useChatContext() as ChatContextType

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    init({ data })
  }, [])

  useEffect(() => {
    if (editMessageData) {
      inputRef.current?.focus()
      setMessage(editMessageData)
    }
    // eslint-disable-next-line
  }, [editMessageData])

  const updateMessageText = (message: string) => setMessage(prev => ({ ...prev, text: message }))

  const handleMessage = (e: any) => {
    search(e.target)
    updateMessageText(e.target.value)
  }

  const search = async (input: HTMLInputElement) => {
    const value = getEmojiSearchString()

    if (value.startsWith(":") && value.length > 1) {

      const emojis = await SearchIndex.search(value.replace(":", ""))
      const results = emojis?.map((emoji: any) => {
        return emoji.skins[0].native
      })
      setEmojiSearch(results || [])
    } else setEmojiSearch([])
  }

  function typeInTextarea(text: string, el = inputRef.current) {
    if (el) {
      const [start, end] = [el.selectionStart, el.selectionEnd]
      const value = el.value
      if (start && end && value) {
        updateMessageText([value.slice(0, start), text, value.slice(start)].join(''))
      }
    } else console.log("ref does not exist")
  }

  const inputEmoji = (emoji: any) => {
    const query = getEmojiSearchString()

    setMessage(prev => ({ ...prev, text: prev.text.replace(query, emoji) }))

    setEmojiSearch([])
    setEmojiSearchSelected(0)
  }

  const keyEvents = (event: KeyboardEvent) => {

    const key = event.key
    const e = emojiSearchElement()

    if (e) {
      switch (key) {

        case "ArrowRight":
          event.preventDefault()
          console.log(e?.selectedCol)
          if (emojiSearchSelected < emojiSearch.length - 1) setEmojiSearchSelected(prev => prev + 1)
          break

        case "ArrowLeft":
          event.preventDefault()
          console.log(e?.selectedCol)
          if (emojiSearchSelected > 0) setEmojiSearchSelected(prev => prev - 1)
          break

        case "ArrowDown":
          event.preventDefault()
          console.log(e?.selectedCol)
          if (emojiSearchSelected + e.amountRow < emojiSearch.length - 1) setEmojiSearchSelected(prev => prev + e.amountRow)
          break

        case "ArrowUp":
          event.preventDefault()
          console.log(e?.selectedCol)
          if (emojiSearchSelected - e.amountRow >= 0) setEmojiSearchSelected(prev => prev - e.amountRow)
          break

        case "Enter":
          event.preventDefault()
          inputEmoji(emojiSearchElement()?.emoji)
          break

        case "Escape":
          event.preventDefault()
          setEmojiSearch([])
          break

      }
    } else {
      switch (key) {
        case "Escape":
          console.log("esc pressed")
          clearMessageMeta()
          break
      }
    }
  }

  const emojiSearchElement = () => {
    const ref = emojiSearchRef.current
    if (ref) {
      const elements = ref.childNodes

      const selected = elements[emojiSearchSelected] as HTMLElement
      const elementWidth = selected.clientWidth

      const containerWidth = ref.clientWidth
      const containerStyle = window.getComputedStyle(ref)
      const containerPadding = containerStyle.getPropertyValue("padding-left")
      const containerPaddingValue = Number(containerPadding.replace("px", ""))

      const amount = elements.length
      const amountRow = Math.floor((containerWidth - (containerPaddingValue * 2)) / elementWidth)
      const amountCol = amount / amountRow

      const selectedCol = emojiSearchSelected % amountRow

      const emoji = selected.innerHTML
      return {
        ref: ref,
        elements: elements,
        amount: amount,
        selected: selected,
        elementWidth: elementWidth,
        containerPadding: containerPaddingValue,
        containerWidth: containerWidth,
        amountRow: amountRow,
        amountCol: amountCol,
        selectedCol: selectedCol,
        emoji: emoji,
      }
    }
  }

  useEffect(() => {
    if (emojiSearchRef && emojiSearchRef.current) {
      const elements = emojiSearchRef.current.childNodes
      const selected = elements[emojiSearchSelected] as HTMLElement

      emojiSearchRef.current.childNodes.forEach((e, i) => {
        const element = elements[i] as HTMLElement
        element.style.backgroundColor = "transparent"
      })
      selected.style.backgroundColor = "rgba(255,255,255,0.3)"
    }
  }, [emojiSearchSelected, emojiSearch])

  useEffect(() => {
    window.addEventListener('keydown', keyEvents)
    return () => window.removeEventListener("keydown", keyEvents)
  })

  useEffect(() => {
    if (emojiIsOpen) setEmojiSearch([])
    // eslint-disable-next-line
  }, [emojiIsOpen])

  const emojiSearchRef = useRef<HTMLDivElement>(null)

  const getEmojiSearchString = () => {
    const el = inputRef.current
    const value = el?.value
    if (value) {
      const start_index = el.selectionStart || 0;
      const end_index = el.selectionEnd || 0;
      const previous_space_index = value.lastIndexOf(":", start_index - 1);
      const next_space_index = value.indexOf(" ", end_index);
      const begin = previous_space_index < 0 ? 0 : previous_space_index;
      const end = next_space_index < 0 ? value.length : next_space_index;
      const between_spaces = value.substring(begin, end);

      return between_spaces
    }
    return ""
  }

  useEffect(() => {
    const links = linkify.find(message.text)
    if (links.length && links[0].href !== message.meta?.requestUrl) fetchMetadata(links[0].href)
    else if (!links.length) clearMessageMeta()

    // eslint-disable-next-line
  }, [message.text])

  const fetchMetadata = async (url: string) => {
    const fetchOGData = httpsCallable(functions, 'fetchOGData');
    fetchOGData({ text: url })
      .then((result: any) => {
        /** @type {any} */
        const data: any = result.data;
        if (data.result) setMessage(prev => ({ ...prev, meta: data.result }))
        else clearMessageMeta()
      })
      .catch(error => {
        clearMessageMeta()
        console.error(error.code, error.message, error.details)
      })
  }

  const editMessage = async (e: any) => {
    e.preventDefault()
    if (validateEdit && chat && editMessageData) {
      const ref = doc(db, messagesPath, editMessageData.id);

      await updateDoc(ref, {
        text: message.text,
        editedTimestamp: Timestamp.fromDate(new Date()),
        meta: message.meta,
      })
        .then(() => {
          setMessage(prev => ({ ...prev, text: "", meta: undefined }))
          setEditMessageData(undefined)
        })
        .catch(error => console.error(error))
    }
  }

  const send = async (e: any) => {
    e.preventDefault()
    if (auth.currentUser && chat) {
      if (message && message.text.trim() !== "") {
        const messageData: MessageData = {
          text: message.text,
          sender: auth.currentUser.uid,
          timestamp: Timestamp.fromDate(new Date()),
          serverTimestamp: serverTimestamp(),
          meta: message.meta,
        }
        await addDoc(collection(db, messagesPath), messageData)
          .then(() => setMessage(prev => ({ ...prev, text: "", meta: undefined })))
          .catch(error => console.error(error.code, error.message))
        await updateDoc(doc(db, `chats/${chat.id}`), {
          lastMessage: messageData
        });
      } else console.log("Empty message")
    }
  }

  const validateEdit = (
    auth.currentUser &&
    editMessageData &&
    message &&
    message.text.trim() !== "" && (
      editMessageData.text.trim() !== message.text.trim() ||
      editMessageData.meta !== message.meta)
  )

  const clearMessageMeta = () => setMessage(prev => ({ ...prev, meta: undefined }))

  return (
    <div className="input-field flex justify-center w-full max-w-screen-sm mx-auto p-4 pb-6">

      <div className="flex flex-col gap-1 w-full">
        {message.meta && (
          <div className="flex gap-2 bg-gray-900 rounded p-1 relative">
            <img
              src={message.meta?.ogImage?.[0].url || message.meta?.favicon}
              onError={e => {
                const img = e.target as HTMLImageElement
                img.src = placeholder
              }}
              className="rounded object-cover aspect-square h-20"
              alt={message.meta?.ogTitle}
              width={message.meta.ogImage?.[0].width}
              height={message.meta.ogImage?.[0].height}
            />
            <div className="flex flex-col gap-1 pt-1 bg-white bg-opacity-5 rounded p-2 flex-1">
              <div className="font-semibold text-sm">{message.meta.ogTitle}</div>
              <div className=" text-xs">{message.meta?.ogDescription}</div>
            </div>
            <div className="absolute top-2 right-2 cursor-pointer" onClick={clearMessageMeta} ><X size={16} /></div>
          </div>
        )}

        <form onSubmit={editMessageData ? editMessage : send} className="flex items-center w-full relative">

          {emojiIsOpen && <div className="absolute left-0 bottom-full transform -translate-y-1">
            <Picker data={data} onEmojiSelect={(e: any) => typeInTextarea(e.native)} onClickOutside={() => setEmojisOpen(false)} />
          </div>}
          {emojiSearch?.length > 0 && <div ref={emojiSearchRef} className="absolute left-0 bottom-full transform -translate-y-1 bg-gray-900 rounded p-1 flex items-center justify-center flex-wrap">
            {emojiSearch.map((emoji: any, i: number) => <div key={i} onClick={() => inputEmoji(emoji)} className="cursor-pointer w-8 h-8 inline-flex items-center justify-center !hover:bg-white hover:bg-opacity-10 p-1 rounded transition-colors duration-300">{emoji}</div>)}
          </div>
          }
          <input
            autoFocus
            ref={inputRef}
            type="text"
            value={message.text}
            onChange={handleMessage}
            className="pl-10 pr-14 bg-gray-900 text-gray-200 py-4 px-3 rounded flex-1 outline-none"
            placeholder="Message"
          />
          <div
            className="absolute left-2 text-gray-300 cursor-pointer hover:opacity-60 transition-opacity duration-300"
            onTouchStart={() => setEmojisOpen(!emojiIsOpen)}
            onMouseOver={() => setEmojisOpen(!emojiIsOpen)}
          >
            <Smile size={24} className=" pointer-events-none" />
          </div>
          {message?.text.trim() && (
            <div className="aspect-square right-1 top-1 bottom-1 absolute">
              <button
                type="submit"
                className="bg-primary rounded w-full h-full flex items-center justify-center hover:shadow-[0_0_0_2px] hover:rounded-sm text-primary transition-shadow duration-100"
              >
                {editMessageData ?
                  (validateEdit ?
                    <Edit2 size={16} className="absolute text-white" /> :
                    <X size={16} className="absolute text-white" />
                  ) : <Send size={16} className="absolute text-white" />
                }
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default memo(ChatInput)