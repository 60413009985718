import { ReactNode } from "react"
import { Link as RouterLink } from "react-router-dom"
import routes from "../routes"

export const Link = ({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) => {

  const external = !Object.values(routes).includes(to)
  const classNames = "text-primary"

  return external ? (
    <a href={`//${to}`} className={classNames} target="_blank" rel="noreferrer">{children}</a>
  ) : (
    <RouterLink to={to} className={classNames}>{children}</RouterLink>
  )
}

export default Link