import { collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, query, QuerySnapshot } from "firebase/firestore"
import React, { useEffect, useRef, useState } from "react"
import { ArrowDown } from "react-feather"
import { useDebouncedCallback } from "use-debounce"
import { ChatContextType, Message as MessageType } from "../../data/types"
import { db } from "../../firebase/firebase"
import useInfiniteScroll from "../../functions/infiniteScroll"
import { messageData } from "../../helper/dataHelper"
import { formattedDate } from "../../helper/dateHelper"
import { useChatContext } from "../../pages/Chat"
import Message from "../Message"

const Messages = () => {

  const {
    chat,

    messages,
    messagesPath,
    setMessages,
    loadMoreMessagesAmount,
    messagesLimit,
    setMessagesLimit,
    setAllMessagesCount,
    setAllMessagesLoaded,

    showArrow,
    setShowArrow,
    chatScrollDistance,
    setChatScrollDistance,

    isFetchingMessages,
    setIsFetchingMessages,
  } = useChatContext() as ChatContextType

  const [counter, setCounter] = useState(0)

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const [moreMessagesAvailable, setMoreMessagesAvailable] = useState(true)

  const getMessagesCount = async () => (await getCountFromServer(query(collection(db, messagesPath)))).data().count

  const getMoreMessagesAvailable = async () => {
    const count = await getMessagesCount()
    setAllMessagesCount(count)
    const moreAvailable = count > messages.length
    setMoreMessagesAvailable(moreAvailable)
    return moreAvailable
  }

  const fetchMessages = async (querySnapshot: QuerySnapshot) => {

    if (!isFetchingMessages) {
      setAllMessagesLoaded(false)

      if (await getMoreMessagesAvailable()) {
        setCounter(prev => prev + 1)

        setIsFetchingMessages(true)

        const tempMessages: MessageType[] = []

        querySnapshot.forEach(doc => {
          tempMessages.push({ ...doc.data() as MessageType, id: doc.id, fromCache: doc.metadata.fromCache })
        })

        setMessages(tempMessages.reverse())

      } else {
        setAllMessagesLoaded(true)
        setIsFetchingMessages(false)
        console.log("no more messages")
      }
    } setIsFetchingMessages(false)
  }

  useEffect(() => {
    console.log(counter)
  }, [counter])

  const loadMoreMessages = async () => {
    if (await getMoreMessagesAvailable()) setMessagesLimit(prev => prev + loadMoreMessagesAmount)
  }

  useEffect(() => {
    if (chat) {
      console.log("listener")
      //console.log("loading messages chat: ", chat)
      const q = query(collection(db, messagesPath), orderBy("timestamp", "desc"), limit(messagesLimit))
      const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, async (querySnapshot) => {
        fetchMessages(querySnapshot)
      })
      return () => unsubscribe()
    }
    // eslint-disable-next-line
  }, [])

  const getMessages = async () => {
    console.log("getMessages")
    if (!isFetchingMessages) {
      setIsFetchingMessages(true)
      const q = query(collection(db, messagesPath), orderBy("timestamp", "desc"), limit(messagesLimit))
      const querySnapshot = await getDocs(q)
      fetchMessages(querySnapshot)
    }
  }

  useEffect(() => {
    getMessages()
    // eslint-disable-next-line
  }, [messagesLimit])

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

  const scrollMessages = (e: HTMLDivElement) => {
    const scrollDistance = Math.abs(e.scrollTop)
    setChatScrollDistance(scrollDistance)
    console.log(scrollDistance)
  }

  useEffect(() => {
    setIsFetchingMessages(false)
    // eslint-disable-next-line
  }, [messages])

  useEffect(() => {
    setShowArrow(chatScrollDistance > 1000)
    // eslint-disable-next-line
  }, [chatScrollDistance])

  const debounced = useDebouncedCallback(value => {
    if (value?.target) scrollMessages(value.target)
  }, 200)

  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const messagesRef = useRef<HTMLDivElement>(null)
  const messageRef = useInfiniteScroll(loadMoreMessages, messagesContainerRef, isFetchingMessages)

  return (
    <div className="relative w-full h-full overflow-hidden flex justify-center">
      <div
        className="messages-container w-full h-full overflow-y-auto overflow-x-hidden scrollbar-thin flex flex-col-reverse"
        key="messages-container"
        ref={messagesContainerRef}
        onScroll={debounced}
      >
        <div
          ref={messagesRef}
          key={"messages"}
          className="messages flex flex-col w-full justify-end p-1"
        >
          {!moreMessagesAvailable && <div className="text-gray-400 rounded py-1 px-2 inline-block self-center justify-self-center text-xs">No more messages</div>}
          {(messages && messages?.length > 0) ?
            messages.map((currentMessage: MessageType, i: number) => {
              const prevMessage = messages[i - 1]
              const nextMessage = messages[i + 1]
              const data = messageData(currentMessage, prevMessage, nextMessage)
              const addTimeDivider = !data.prevIsSameDay
              return (
                <React.Fragment key={` message-${currentMessage.id}`}>
                  {addTimeDivider && (
                    <div className="flex items-center">
                      <hr className="flex-1 bg-gray-700 border-none h-px m-8" />
                      <p className="text-center text-sm text-gray-500">{formattedDate(data.currentDate)}</p>
                      <hr className="flex-1 bg-gray-700 border-none h-px m-8" />
                    </div>
                  )}
                  <Message
                    ref={i === 0 ? messageRef : undefined}
                    currentMessage={currentMessage}
                    prevMessage={prevMessage}
                    nextMessage={nextMessage}
                  />
                </React.Fragment>
              )
            }) : <p className="rounded bg-gray-800 text-gray-400 py-1 px-4 self-center justify-self-center m-4">No messages</p>
          }
          <div ref={messagesEndRef} />
        </div>
      </div>
      {showArrow && (
        <button onClick={scrollToBottom} className="absolute bottom-1 w-8 h-8 bg-primary text-primary text-sm inline-flex gap-2 items-center justify-center rounded-full outline-none transition-shadow duration-300 hover:shadow-[0_0_0_1px,inset_0_0_0_2px] ">
          <ArrowDown size={16} className="text-white" />
        </button>
      )}
    </div>
  )
}

export default Messages