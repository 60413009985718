import { ComponentPropsWithoutRef, memo, ReactNode } from "react"

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  children: ReactNode
}

const Button = ({
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className="
        py-2 px-4 text-sm text-gray-50 rounded bg-primary hover:opacity-80 transition-opacity duration-300 uppercase inline-flex gap-2 items-center justify-center
        disabled:bg-gray-400 disabled:cursor-not-allowed
      "
    >{children}</button>
  )
}

export default memo(Button)