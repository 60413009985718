import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { arrayUnion, doc, initializeFirestore, persistentLocalCache, updateDoc } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getToken, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyAu35ruVwxC2TQ24Y7I0mQvkEqHddpg85Q",
  authDomain: "chat-eda58.firebaseapp.com",
  projectId: "chat-eda58",
  storageBucket: "chat-eda58.appspot.com",
  messagingSenderId: "591290941722",
  appId: "1:591290941722:web:754da0952fa1345ffcaca3"
}

export const firebase = initializeApp(firebaseConfig)
export const messaging = getMessaging(firebase)
export const auth = getAuth()
export const db = initializeFirestore(firebase, {
  localCache: persistentLocalCache(),
  ignoreUndefinedProperties: true,
})
export const functions = getFunctions(firebase)

export const logout = () => {
  signOut(auth).then(() => {
    console.log("signed out")
  }).catch((error) => {
    console.error(error.message)
  });
}

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload)
})

export const handleToken = async () => {
  return new Promise<string>(async (resolve, reject) => {
    if (auth.currentUser) {
      if (Notification.permission === "granted") {
        await getToken(messaging, { vapidKey: "BMbo5Rdkjr3Yq4LYIwIPVbPLuCaYF2JsZOPrkBSMpCDJFSGB3nW5HSqPtHRjwwQ7uMr4rJRWuV-EID14yVASmsM" })
          .then(async (token) => {
            if (auth.currentUser) {
              await updateDoc(doc(db, "users", auth.currentUser.uid), {
                notificationTokens: arrayUnion(token)
              })
                .then(() => resolve(token))

            } else return reject("You need to be signed in.")
          })
          .catch(e => reject(e))
      } else return reject("Insufficient Notification Permissions.")
    } else return reject("You need to be signed in.")
  })
}

export const handleNotificationPermission = () => {
  new Promise((resolve) => Notification
    .requestPermission(resolve)
    ?.then(resolve))
    .then(async () => {
      await handleToken()
        .catch(e => console.error(e))
    })
}