import { memo } from "react"

const Loader = () => {

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-gray-900 flex items-center justify-center z-[999]">
      <span className="loader" />
    </div>
  )
}

export default memo(Loader)