
import { onAuthStateChanged, signInWithEmailAndPassword, User } from "firebase/auth"
import { useEffect, useState } from "react"
import { LogIn } from "react-feather"
import { useLocation, useNavigate } from "react-router-dom"
import { isEmail } from "validator"
import Container from "../components/Container"
import Form, { Input } from "../components/Form"
import Link from "../components/Link"
import { auth } from "../firebase/firebase"
import routes from "../routes"

const Signin = () => {

  const [user, setUser] = useState<User | null>(null)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<string | undefined>()
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState<string | undefined>()
  const [formError, setFormError] = useState<string | undefined>()

  const handleEmail = (e: any) => setEmail(e.target.value)
  const handlePassword = (e: any) => setPassword(e.target.value)

  const navigate = useNavigate()
  const location = useLocation()

  const validate = () => {
    const validEmail = isEmail(email)
    const validPassword = Boolean(password.trim())

    return { email: validEmail, password: validPassword, all: (validEmail && validPassword) }
  }

  const setErrorStates = () => {
    const { email, password } = validate()
    setEmailError(email ? undefined : "Invalid Email.")
    setPasswordError(password ? undefined : "Password too insecure.")
  }

  const login = (e: any) => {
    e.preventDefault()
    setErrorStates()
    if (validate().all) {
      signInWithEmailAndPassword(auth, email, password)
        .then(u => navigate(routes.home))
        .catch(error => setFormError(error.code))
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      setUser(u)
    })
  }, [])

  useEffect(() => {
    if (user && location.pathname === routes.home) return navigate(routes.home)
    // eslint-disable-next-line
  }, [user, location.pathname])

  return (
    <>
      <Container>
        <Form
          title="Sign-in"
          onSubmit={login}
          error={formError}
          valid={validate().all}
          submitButton={<><LogIn size={14} />Login</>}
          hint={<>Don't have an account yet? <Link to={routes.signup}>Sign up</Link></>}>
          <Input label="Email" type="email" value={email} onChange={handleEmail} error={emailError} />
          <Input label="Password" type="password" value={password} onChange={handlePassword} error={passwordError} />
        </Form>
      </Container>
    </>
  )
}

export default Signin