import { onAuthStateChanged, User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { createContext, memo, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChatInput from "../components/chat/ChatInput";
import Header from '../components/chat/Header';
import Messages from '../components/chat/Messages';
import ChatList from '../components/ChatList';
import Loader from "../components/Loader";
import { ChatContextType, Chat as ChatType, Message as MessageType } from "../data/types";
import { auth } from "../firebase/firebase";

export const ChatContext = createContext<ChatContextType | undefined>(undefined)

export const useChatContext = () => useContext(ChatContext)

const Chat = () => {
  const [user, setUser] = useState<User | null>(null)

  // const localCurrentChat = sessionStorage.getItem("currentChat")
  // const localCurrentChatJSON = localCurrentChat ? JSON.parse(localCurrentChat) as ChatType : undefined
  const [chat, setChat] = useState<ChatType | undefined>()
  const [showChatList, setShowChatList] = useState(!chat)

  const [messages, setMessages] = useState<MessageType[]>([])
  const loadMoreMessagesAmount = 10
  const [messagesLimit, setMessagesLimit] = useState(loadMoreMessagesAmount)
  const [allMessagesCount, setAllMessagesCount] = useState<number>(0)
  const [allMessagesLoaded, setAllMessagesLoaded] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [showArrow, setShowArrow] = useState(false)
  const [chatScrollDistance, setChatScrollDistance] = useState<number>(0)

  const [isFetchingMessages, setIsFetchingMessages] = useState<boolean>(false)

  const [emojiIsOpen, setEmojisOpen] = useState(false)
  const [emojiSearch, setEmojiSearch] = useState<any[]>([])
  const [emojiSearchSelected, setEmojiSearchSelected] = useState<number>(0)
  const [emojiSearchString, setEmojiSearchString] = useState("")

  const [editMessageData, setEditMessageData] = useState<MessageType | undefined>()

  const [message, setMessage] = useState<MessageType>({
    id: "",
    text: "",
    sender: "",
    timestamp: Timestamp.fromDate(new Date()),
    serverTimestamp: Timestamp.fromDate(new Date()),
    fromCache: false,
  })

  const [messagesPath, setMessagesPath] = useState("")

  useEffect(() => {
    // console.log("chat changed: ", chat)
    setMessagesPath(`messages/${chat?.id}/messages`)
    chat && sessionStorage.setItem("currentChat", JSON.stringify(chat))
  }, [chat])

  useEffect(() => {
    onAuthStateChanged(auth, async (u) => setUser(u))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (messages && user) setIsLoading(false)
    else setIsLoading(true)
  }, [messages, user])

  const getOtherParticipants = (chat?: ChatType) => chat?.members.filter(e => e.uid !== auth.currentUser?.uid)

  return !isLoading ? auth.currentUser ? (
    <ChatContext.Provider value={{
      chat,
      setChat,
      showChatList,
      setShowChatList,

      messages,
      messagesPath,
      setMessages,
      loadMoreMessagesAmount,
      messagesLimit,
      setMessagesLimit,
      allMessagesCount,
      setAllMessagesCount,
      allMessagesLoaded,
      setAllMessagesLoaded,

      isLoading,
      setIsLoading,

      showArrow,
      setShowArrow,
      chatScrollDistance,
      setChatScrollDistance,

      isFetchingMessages,
      setIsFetchingMessages,

      emojiIsOpen,
      setEmojisOpen,
      emojiSearch,
      setEmojiSearch,
      emojiSearchSelected,
      setEmojiSearchSelected,
      emojiSearchString,
      setEmojiSearchString,

      editMessageData,
      setEditMessageData,

      message,
      setMessage,
    }}>
      <div className="bg-gray-950 w-full max-w-screen-sm mx-auto flex flex-col h-full overflow-hidden px-2 transition-all duration-300 delay-0 relative">

        <Header title={chat?.group?.name || getOtherParticipants(chat)?.[0]?.displayName || ""} setShowChatList={setShowChatList} />

        <Messages />

        <ChatInput />

        <ChatList />

      </div>

    </ChatContext.Provider>
  ) : <Link to="/login">Login</Link> : <Loader />
}

export default memo(Chat)