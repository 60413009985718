const locale = "en-MY" //(navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language || ["en"];

export const formattedDateFull = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const formattedDateFullWithTime = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const formattedTime = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "2-digit",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const formattedDateWithTime = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const formattedDate = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const formattedMonthAndYear = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  }).format(new Date(timestamp)).replaceAll("/", ".")
};

export const lastDayOfMonth = (year: number, month: number): number => new Date(year, month + 1, 0).getDate()

export const timeZero = (date: Date) => {
  const d = date

  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)

  return d
}

export const datesOfWeek = (date: Date) => {
  const week: Date[] = [];
  const d = date

  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)

  d.setDate((d.getDate() - d.getDay()))

  for (var i = 0; i < 7; i++) {
    week.push(
      new Date(d)
    );
    d.setDate(d.getDate() + 1)
  }

  return week
}

export const toDateInputValue = (dateObject: Date) => {
  const local = new Date(dateObject);
  local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

export const getDaysOfMonth = (date: Date = new Date()) => {
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { first: firstDay, last: lastDay }
}

export const isSameDay = (a: Date, b: Date) => {
  const left = new Date(a)
  const right = new Date(b)
  left.setHours(0, 0, 0, 0)
  right.setHours(0, 0, 0, 0)
  return (
    left.getDate() === right.getDate() &&
    left.getMonth() === right.getMonth() &&
    left.getFullYear() === right.getFullYear()
  )
}

export const isSameMinute = (a: Date, b: Date) => (
  a.getMinutes() === b.getMinutes() &&
  a.getHours() === b.getHours() &&
  a.getDate() === b.getDate() &&
  a.getMonth() === b.getMonth() &&
  a.getFullYear() === b.getFullYear()
)
