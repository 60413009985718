import { onAuthStateChanged } from "firebase/auth"
import { deleteDoc, doc } from "firebase/firestore"
import Linkify from "linkify-react"
import * as linkify from "linkifyjs"
import { ComponentPropsWithRef, ForwardedRef, forwardRef, memo, useEffect, useState } from "react"
import {
  Item,
  Menu,
  Submenu,
  useContextMenu
} from "react-contexify"
import "react-contexify/dist/ReactContexify.css"
import { ChevronRight, Copy, Edit2, Trash } from "react-feather"
import { ChatContextType, Message as MessageType } from "../data/types"
import { auth, db } from "../firebase/firebase"
import { messageData } from "../helper/dataHelper"
import { formattedDate, formattedTime } from "../helper/dateHelper"
import { copy } from "../helper/stringHelper"
import { useChatContext } from "../pages/Chat"

interface MessageProps extends ComponentPropsWithRef<"div"> {
  currentMessage: MessageType
  prevMessage: MessageType | undefined
  nextMessage: MessageType | undefined
}

const Message = forwardRef(({
  currentMessage,
  prevMessage,
  nextMessage,
}: MessageProps, ref: ForwardedRef<HTMLDivElement | null>) => {

  const { messagesPath, setEditMessageData } = useChatContext() as ChatContextType

  const MENU_ID = `menu-id-${currentMessage.id}`

  const [uid, setUid] = useState<string | null>(null)

  const isOwnMessage = currentMessage.sender === uid

  const data = messageData(currentMessage, prevMessage, nextMessage)

  const prevIsSameOwner = data.prevIsSameOwner
  const nextIsSameOwner = data.nextIsSameOwner

  const prevIsSameMinute = data.prevIsSameMinute
  const nextIsSameMinute = data.nextIsSameMinute

  useEffect(() => {
    onAuthStateChanged(auth, async (u) => setUid(u?.uid || null))
    // eslint-disable-next-line
  }, [])

  const deleteMessage = async (id: string) => await deleteDoc(doc(db, messagesPath, id))
    .then(() => console.log(`deleted message ${id}`))
    .catch(error => console.error(error.message))

  const editMessage = async (id: string) => setEditMessageData(currentMessage)

  const { show } = useContextMenu({ id: MENU_ID })

  function displayMenu(e: any) {
    show({ event: e });
  }

  const links = linkify.find(currentMessage.text)
  const ogImage = currentMessage.meta?.ogImage?.[0]

  const group = {
    last: !nextIsSameMinute || (nextIsSameMinute && !nextIsSameOwner) || (!nextIsSameMinute && !nextIsSameOwner) || !nextMessage,
    own: {
      hasPrev: isOwnMessage && prevIsSameMinute && prevIsSameOwner,
    },
    other: {
      hasPrev: !isOwnMessage && prevIsSameMinute && prevIsSameOwner,
    }
  }

  return (
    <div
      ref={ref}
      onContextMenu={displayMenu}
      onTouchStart={displayMenu}
      className={`message text-sm py-2 px-2 relative max-w-[min(60%,90rem)] min-w-14 outline-none border-none rounded
          ${isOwnMessage ?
          "message-own animate-slideInRight rounded-br-none items-end self-end bg-primary after:text-primary" :
          "message-other animate-slideInLeft rounded-bl-none items-start self-start bg-gray-800 after:text-gray-800"}
          ${group.own.hasPrev ? "rounded-tr-none" : ""}
          ${group.last ? "message-has-tail" : ""}
          ${group.other.hasPrev ? "rounded-tl-none" : ""}
          ${prevIsSameMinute ? "mt-0.5" : "mt-4"}
        `}
    >
      <p>
        <Linkify options={{ target: "_blank", className: "underline inline", nl2br: true }} >{currentMessage.text}</Linkify>
      </p>
      {currentMessage.meta && (
        <a href={currentMessage.meta.ogUrl} target="_blank" rel="noreferrer" className="flex flex-col gap-2 rounded bg-black bg-opacity-10 p-2">
          <div className="flex flex-col gap-1 pt-1 flex-1 hover:bg-opacity-10 transition-colors duration-300">
            <div className="font-semibold text-sm">{currentMessage.meta.ogTitle}</div>
            <div className=" text-xs">{currentMessage.meta.ogDescription}</div>
          </div>
          <img
            src={ogImage?.url || currentMessage.meta?.favicon}
            onError={e => {
              const img = e.target as HTMLImageElement
              img.remove()
            }}
            className="rounded w-full"
            width={ogImage?.width}
            height={ogImage?.height}
            alt={currentMessage.meta.ogTitle}
          />
        </a>
      )}
      {currentMessage.fromCache && <div className="w-1 h-1 rounded-full bg-gray-50 absolute top-1 left-1" />}
      <div className="flex items-baseline gap-2">
        {currentMessage.editedTimestamp && <div className="text-xs text-gray-300 italic">Edited</div>}
        {group.last && (
          <div className={`text-xs whitespace-nowrap  ${isOwnMessage ? "text-right text-gray-300" : "text-left text-gray-500"}`}>
            {false && formattedDate(data.currentDate)} {formattedTime(data.currentTime)}
          </div>
        )}
      </div>

      <Menu id={MENU_ID} theme="dark">
        <Item onClick={() => editMessage(currentMessage.id)}><Edit2 size={16} /> Edit</Item>
        <Item onClick={() => copy(currentMessage.text)}><Copy size={16} /> Copy Message</Item>
        {links.length &&
          <Submenu hidden label={<><Copy size={16} /> Copy Links</>} arrow={<ChevronRight size={16} className="text-white" />}>
            {links.map((link, i) => <Item key={i} onClick={() => copy(link.href)}>{link.href}</Item>)}
          </Submenu>
        }
        <Item onClick={() => deleteMessage(currentMessage.id)}><span className="text-red-600"><Trash size={16} /></span><span className="text-red-600">Delete</span></Item>
      </Menu>
    </div>
  )
})

export default memo(Message)