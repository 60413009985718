import { RouteObject } from "react-router-dom";
import Chat from "./pages/Chat";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";

const routes = {
  home: "/",
  signup: "/signup",
  signin: "/signin",
}

export const routesElements: RouteObject[] = [{
  path: routes.home,
  element: <Chat />,
}, {
  path: routes.signup,
  element: <Signup />,
}, {
  path: routes.signin,
  element: <Signin />,
},]

export default routes