import { memo, SetStateAction } from "react"
import { ChevronLeft } from "react-feather"

const Header = ({
  title,
  setShowChatList,
}: {
  title: string
  setShowChatList: (value: SetStateAction<boolean>) => void
}) => {
  return (
    <header className="w-full max-w-screen-sm mx-auto p-2">
      <div className="flex items-center justify-between gap-2 w-full h-full bg-gray-900 rounded p-4">
        <div className="cursor-pointer" onClick={() => setShowChatList(true)}>
          <ChevronLeft size={18} />
        </div>
        {title && <div className="text-center font-semibold">{title}</div>}
        <div className=""></div>
      </div>
    </header>
  )
}

export default memo(Header)