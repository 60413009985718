import { ComponentPropsWithoutRef, FormEventHandler, ReactNode } from "react"
import Button from "./Button"

const Form = ({
  title,
  children,
  onSubmit,
  submitButton,
  hint,
  error,
  valid = true,
}: {
  title?: string
  children: ReactNode
  onSubmit: FormEventHandler<HTMLFormElement> | undefined
  submitButton?: ReactNode
  hint?: ReactNode
  error?: string
  valid?: boolean
}) => {
  return (
    <div className="">
      {title && <h1 className="text-xl font-semibold mb-4 text-center">{title}</h1>}
      <form id="form-login" onSubmit={onSubmit} className="flex gap-4 flex-col max-w-80 mx-auto justify-self-center">
        {children}
        {error && (
          <>
            <Divider />
            <Error>{error}</Error>
          </>
        )}
        <Divider />
        {submitButton && <Button type="submit" className="self-end justify-self-end" disabled={!valid}>{submitButton}</Button>}
      </form>
      <div className="flex items-center justify-center py-2 gap-1 text-xs text-gray-300">{hint}</div>
    </div>
  )
}

export default Form

interface InputProps extends ComponentPropsWithoutRef<"input"> {
  label: string
  error?: string
}

export const Input = ({
  label,
  error,
  ...props
}: InputProps) => {
  return (
    <label className="flex flex-col gap-1 pb-1">
      <p className="text-xs text-gray-400">{label}</p>
      <input {...props} type={props.type || "text"} className="py-2 px-2 border border-gray-600 rounded text-sm bg-transparent outline-none" />
      <Error>{error}</Error>
    </label>
  )
}

const Divider = () => <hr className="bg-gray-600 border-none h-px w-full" />

const Error = ({ children }: { children?: string }) => children ? <p className="text-xs text-red-400">{children}</p> : <></>