
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import { useState } from "react"
import { LogIn } from "react-feather"
import { useNavigate } from "react-router-dom"
import isEmail from 'validator/lib/isEmail'
import Container from "../components/Container"
import Form, { Input } from "../components/Form"
import Link from "../components/Link"
import { auth, db } from "../firebase/firebase"
import routes from "../routes"

const Signup = () => {

  const [displayName, setdisplayName] = useState("")
  const [userNameError, setdisplayNameError] = useState<string | undefined>()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<string | undefined>()
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState<string | undefined>()
  const [formError, setFormError] = useState<string | undefined>()

  const handleEmail = (e: any) => setEmail(e.target.value)
  const handledisplayName = (e: any) => setdisplayName(e.target.value)
  const handlePassword = (e: any) => setPassword(e.target.value)

  const navigate = useNavigate()

  const validate = () => {
    const validEmail = isEmail(email)
    const validPassword = Boolean(password.trim())
    const validdisplayName = Boolean(displayName.trim())

    return { email: validEmail, password: validPassword, displayName: validdisplayName, all: (validEmail && validPassword && validdisplayName) }
  }

  const setErrorStates = () => {
    const { email, password, displayName } = validate()
    setEmailError(email ? undefined : "Invalid Email.")
    setPasswordError(password ? undefined : "Password too insecure.")
    setdisplayNameError(displayName ? undefined : "Invalid Username")
  }

  const login = (e: any) => {
    e.preventDefault()
    setErrorStates()
    if (validate().all) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user
          await setDoc(doc(db, "users", user.uid), {
            displayName: displayName
          })
            .then(async () =>
              await updateProfile(user, {
                displayName: displayName
              })
                .then(() => navigate(routes.home))
                .catch(error => setFormError(error.code))
            )
            .catch(error => setFormError(error.code))
        })
        .catch(error => setFormError(error.code))
    }
  }

  return (
    <>
      <Container>
        <Form
          title="Sign-up"
          onSubmit={login}
          error={formError}
          valid={validate().all}
          submitButton={<><LogIn size={14} />Sign up</>}
          hint={<>Already have an account? <Link to={routes.signin}>Sign in</Link></>}
        >
          <Input label="Email" type="email" value={email} onChange={handleEmail} error={emailError} />
          <Input label="Password" type="password" value={password} onChange={handlePassword} error={passwordError} />
          <Input label="Name" type="string" value={displayName} onChange={handledisplayName} error={userNameError} />
        </Form>
      </Container>
    </>
  )
}

export default Signup