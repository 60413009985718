import { Message } from "../data/types"
import { isSameDay, isSameMinute } from "./dateHelper"

export interface MessageData {
  message: string

  prevOwner?: string
  nextOwner?: string

  prevIsSameOwner: boolean
  nextIsSameOwner: boolean

  currentTime: Date
  currentDate: Date

  nextDate: Date
  prevDate: Date

  prevIsSameDay: boolean
  nextIsSameDay: boolean

  prevIsSameMinute: boolean
  nextIsSameMinute: boolean
}

export const messageData = (
  currentMessage: Message,
  prevMessage?: Message,
  nextMessage?: Message
): MessageData => {
  const message = currentMessage.text

  const prevOwner = prevMessage?.sender
  const nextOwner = nextMessage?.sender

  const prevIsSameOwner = currentMessage.sender === prevOwner
  const nextIsSameOwner = currentMessage.sender === nextOwner

  const currentTime = new Date(currentMessage.timestamp.seconds * 1000)
  const currentDate = new Date(currentMessage.timestamp.seconds * 1000)

  currentDate.setHours(0, 0, 0, 0)
  const a = new Date(currentDate)

  const prevDate = prevMessage ? new Date(prevMessage.timestamp.seconds * 1000) : new Date(a.getDate() - 1)
  const nextDate = nextMessage ? new Date(nextMessage.timestamp.seconds * 1000) : new Date(a.getDate() + 1)

  const prevIsSameDay = isSameDay(prevDate, currentDate)
  const nextIsSameDay = isSameDay(nextDate, currentDate)

  const prevIsSameMinute = isSameMinute(prevDate, currentTime)
  const nextIsSameMinute = isSameMinute(nextDate, currentTime)

  return {
    message: message,

    prevOwner: prevOwner,
    nextOwner: nextOwner,

    prevIsSameOwner: prevIsSameOwner,
    nextIsSameOwner: nextIsSameOwner,

    currentTime: currentTime,
    currentDate: currentDate,

    prevDate: prevDate,
    nextDate: nextDate,

    prevIsSameDay: prevIsSameDay,
    nextIsSameDay: nextIsSameDay,

    prevIsSameMinute: prevIsSameMinute,
    nextIsSameMinute: nextIsSameMinute,
  }
}
