import { collection, onSnapshot, query, where } from "firebase/firestore"
import { memo, useEffect, useState } from "react"
import { Bell, BellOff, LogOut } from "react-feather"
import { Chat, ChatContextType } from "../data/types"
import { auth, db, handleNotificationPermission, logout } from "../firebase/firebase"
import { useChatContext } from "../pages/Chat"

const ChatList = () => {
  const {
    chat,
    setChat,
    showChatList,
    setShowChatList
  } = useChatContext() as ChatContextType

  const [chats, setChats] = useState<Chat[] | undefined>()

  useEffect(() => {
    if (auth.currentUser) {
      console.log("fetching chats")
      const q = query(collection(db, "chats"), where("members", "array-contains", { uid: auth.currentUser.uid, displayName: auth.currentUser.displayName }))
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tempChats: Chat[] = []
        querySnapshot.forEach((doc) => {
          tempChats.push({ ...doc.data() as Chat, id: doc.id })
        })
        setChats(tempChats)
      })
      return () => unsubscribe()
    }
    // eslint-disable-next-line
  }, [auth.currentUser])

  const getOtherParticipants = (chat: Chat) => {
    return chat.members.filter(e => e.uid !== auth.currentUser?.uid)
  }

  return (
    <div className={`absolute z-20 left-0 top-0 bottom-0 bg-gray-900 text-white w-full transition-transform duration-300 ${showChatList ? "" : "-translate-x-full"}`}>

      <div className="p-4 flex justify-between items-center">
        <div className="flex-1" >
          <div className="cursor-pointer" onClick={handleNotificationPermission}>
            {Notification.permission === "granted" ? <Bell size={18} /> : <BellOff size={18} />}
          </div>
        </div>
        <h2 className="text-lg font-semibold">Chats</h2>
        <div className="flex-1 flex justify-end items-center gap-4">
          <div className="font-semibold text-sm text-gray-400">{auth.currentUser?.displayName}</div>
          <div className="cursor-pointer" onClick={logout} >
            <LogOut size={18} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 p-2" onClick={() => setShowChatList(false)}>
        {chats?.map((currentChat, i) => {
          const otherParticipant = getOtherParticipants(currentChat)[0]
          const group = currentChat.group
          return (
            <div
              key={i}
              className="flex gap-4 p-2 rounded cursor-pointer hover:bg-white hover:bg-opacity-5 transition-colors duration-300"
              onClick={() => { if (!chat || (chat?.id !== currentChat.id)) setChat(currentChat) }}
            >
              <div className="w-14 h-14 rounded-full bg-gray-800">
                {(group?.image || otherParticipant.image) && <img src={group?.image || otherParticipant.image} className="w-full h-full object-cover" alt="" />}
              </div>
              <div className="flex flex-col gap-1 flex-1">
                <div className="font-semibold">
                  {currentChat.group?.name || otherParticipant.displayName}
                </div>
                <div className="text-sm text-gray-500">
                  {currentChat.lastMessage?.text || ""}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(ChatList)